@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap');

.ConnectButton {
  display: flex;
  background-color: #FFFFFF;
  width: 100%;
  cursor: pointer;
  border: none;
  padding: 8px;
  border-radius: 3px;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, .2), 0px 2px 2px 0px rgba(0, 0, 0, .14), 0px 1px 5px 0px rgba(0, 0, 0, .12);

  &:focus, &:active, &:hover {
    border: none;
    outline: none;
  }

  span {
    font-family: "Roboto";
    font-weight: 500;
    color: rgba(0, 0, 0, .54);
    flex-grow: 1;
    font-size: 14px;
  }

  svg {
    font-size: 18px;
  }
}